import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import bannerData from '../data/bannerData';
import { Box, Container, Card, CardMedia, Stack, Typography } from '@mui/material';
import { styled } from "@mui/material/styles";

const Single = ({postsData}) => {
    const { slug } = useParams();
    const location = useLocation();
    // we must use ? so it load only when we click on page.
    const newsType = location.state?.newsType;
    const [single, setSingle] = useState(null);

    useEffect(() => {
        if (slug && newsType) {
            const foundSingle = postsData.find((item) => item.slug === slug)
            setSingle(foundSingle);
        }
    }, [slug, newsType]);

    if (!single) return <p>Loading...</p>;

    const SingleCard = styled(Card)(({ theme }) => ({
        position: 'relative', display: 'flex', justifyContent: 'center', maxWidth: '1024px', height: '400px',
        [theme.breakpoints.down('md')]: {
            maxWidth: '1000px'
        }
    }));

    return (
        <>
            <NavBar />
            <Banner data={newsType === 'ai-news' ? bannerData.aiNews : bannerData.trading} />
            <Box sx={{ backgroundImage: "url('/images/ai-news-background.png')", backgroundColor: 'primary.dark', backgroundRepeat: 'no-repeat', backgroundBlendMode:'darken' }}>
                <Container maxWidth="md" component='section' sx={{ padding: '80px 30px !important' }}>
                    <Stack gap='40px'>
                        <SingleCard>
                            <CardMedia
                                component="img"
                                src={single.featuredImage}
                                title="bull running"
                            />
                        </SingleCard>
                        <Typography color='secondary' style={{ fontWeight: '600', textAlign: 'center' }}>{single.title}</Typography>
                        <Typography dangerouslySetInnerHTML={{ __html: single.content }}>
                            {/* {single.content} */}
                        </Typography>
                    </Stack>
                </Container>
            </Box>
            <Footer />
        </>
    );
};

export default Single;
