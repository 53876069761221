import React, { useState, useEffect, useRef } from 'react';
import { PopupBackground, PopupCard, PopupCardTitle, PopupContent, PopupButton, PopupButtonText, PopupTitle, PopupTitleContainer, PopupStack, PopupContainer, PopupMainNew, PopupLogoContainer, PopupBreak, PopupPremiumButton, PopupPremiumButtonText, PopupPremiumCard, PopupPremiumCardTitle, PopupPremiumContent, PopupHovered, PopupTitleImage, PopupTitleAi, PopupComingsoon, PopupComingsoonContainer, PopupComingsoonText, PopupComingsoonCancel, PopupComingsoonStars, PopupComingsoonStarsSecond } from '../styles/popupStyled';
import {Box, CardActions, CardContent, } from '@mui/material';
import popupData from '../data/popupData';

const data = popupData;

const Popup = () => {
    const [isComingSoonVisible, setComingSoonVisible] = useState(false);
    const premiumCardRef = useRef(null);
    const handleShowComingSoon = () => setComingSoonVisible(true);
    const handleHideComingSoon = () => setComingSoonVisible(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!premiumCardRef.current.contains(event.target)) {
                handleHideComingSoon();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <PopupMainNew>
           
            {/* Background Overlay */}
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    transition: 'opacity 0.3s ease',
                    opacity: isComingSoonVisible ? 1 : 0,
                    pointerEvents: isComingSoonVisible ? 'auto' : 'none',
                    zIndex: 9,
                }}
            />
            <PopupComingsoonContainer
                style={{
                    transform: isComingSoonVisible ? 'translateX(0)' : 'translateX(100%)',
                    transition: 'transform 0.3s ease-in-out',
                    zIndex: 10,
                }}
            >
                <PopupComingsoon>
                    <PopupComingsoonCancel src="/images/cancel.png" alt="cancel" />
                    <PopupComingsoonText>Coming soon...</PopupComingsoonText>
                    <PopupComingsoonStars src="/images/stars.png" alt="" />
                </PopupComingsoon>
            </PopupComingsoonContainer>
            <PopupBackground>
                <PopupLogoContainer>
                    <img src="/images/top500-logo.png" alt="Logo" style={{ maxWidth: '70px', height: 'auto' }} />
                </PopupLogoContainer>
                <PopupContainer maxWidth='popup'>
                    <PopupTitleContainer sx={{ position: 'relative' }}>
                        <PopupTitle>Unlock <PopupBreak /> the power of</PopupTitle>
                        <PopupTitleAi src="/images/ai-blue.png" alt="ai-blue" />
                        <PopupTitleImage src="/images/stars-blue.png" alt="stars-blue" />
                    </PopupTitleContainer>
                    <PopupStack>
                        {data.map((item, index) => (
                            <PopupCard
                                key={index}
                                onClick={handleShowComingSoon}
                            >
                                <CardContent sx={{ padding: '0' }}>
                                    <PopupCardTitle>{item.title}</PopupCardTitle>
                                    <PopupContent>{item.content}</PopupContent>
                                </CardContent>
                                <CardActions sx={{ padding: '0' }}>
                                    <PopupButton variant='outlined' disableRipple disableElevation sx={{ '&:hover': { background: '#B9FFF1' } }}>
                                        <PopupButtonText>{item.button}</PopupButtonText>
                                    </PopupButton>
                                </CardActions>
                            </PopupCard>
                        ))}

                        <PopupPremiumCard ref={premiumCardRef} onClick={handleShowComingSoon}>
                            <PopupHovered />
                            <CardContent sx={{ padding: '0' }}>
                                <PopupPremiumCardTitle>ai trader</PopupPremiumCardTitle>
                                <PopupPremiumContent>
                                    Unlock exclusive strategies and insights with premium access to AI-driven trading.
                                </PopupPremiumContent>
                            </CardContent>
                            <CardActions sx={{ padding: '0' }}>
                                <PopupPremiumButton variant='outlined' disableRipple disableElevation>
                                    <PopupPremiumButtonText>Join Premium</PopupPremiumButtonText>
                                </PopupPremiumButton>
                            </CardActions>
                            <PopupComingsoonStarsSecond src="/images/stars.png" alt="stars" />
                        </PopupPremiumCard>
                    </PopupStack>
                </PopupContainer>
            </PopupBackground>            
        </PopupMainNew>
    );
};

export default Popup;
