import axios from "axios";
import { useState, useEffect } from "react";
import Banner from "../../components/Banner";
import bannerData from "../../data/bannerData"
import LatestAiBlog from "./LatestAiBlog";
import LatestAiTools from "./LatestAiTools";
import NextWebinar from "./NextWebinar";
import Navbar from "../../components/NavBar"
import Footer from "../../components/Footer";





function Home({posts}) {


    return (
        <>
            <Navbar />
            <Banner data={bannerData.home} />
            <LatestAiBlog posts={posts} />
            <NextWebinar />
            <LatestAiTools posts={posts} />
            <Footer />
        </>
    );
}

export default Home;