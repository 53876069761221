import React from 'react';
import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Home from './pages/home/Home';
import Webinar from './pages/webinar/Webinar'
import PrivacyPolicy from './pages/PrivacyPolicy';
import Popup from './pages/Popup';
import AiBlog from './pages/ai-blog/AiBlog'
import AiTools from './pages/ai-tools/AiTools'
import AiTrader from './pages/ai-trader/AiTrader';
import SinglePost from './pages/SinglePost';


function App() {


  const [posts, setPosts] = useState([]);





  useEffect(() => {
      // I must add embed query so i can extract image data.
      axios.get('https://www.top500ai.com/top500ai_wordpress/wp-json/wp/v2/posts?_embed')
          .then(response => {
              const simplifiedPosts = response.data.map(post => ({
                  id: post.id,
                  title: post.title.rendered,
                  content: post.content.rendered,
                  featuredImage: post._embedded?.["wp:featuredmedia"]?.[0]?.source_url || null,
                  slug:post.slug,
                  categoryName: post._embedded?.["wp:term"]?.[0]?.[0]?.name || 'Uncategorized',
                  
              }));
              // console.log(simplifiedPosts);
              setPosts(simplifiedPosts); 
          })
          .catch(error => console.error(error));
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Popup />} />
      <Route path="/home" element={<Home posts={posts} />} />
      <Route path="/ai-blog" element={<AiBlog posts={posts} />} />
      <Route path="/ai-tools" element={<AiTools posts={posts} />} />   
      <Route path="/ai-trader" element={<AiTrader posts={posts} />} />   
      <Route path="/webinar" element={<Webinar />} />
      <Route path="/single/:slug" element={<SinglePost postsData={posts}/>} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />   
   
</Routes>
  );
}

export default App;
