const webinarsData = [
    {
        name: 'Chinese stimulus and the impact on Oil',
        videoId: 'hn7U2j4LSlI'
    },
    {
        name: 'Falling USD ahead of BRICS summit',
        videoId: 'hQpAGAgktGA'
    },
    {
        name: 'Earnings Season kicks off',
        videoId: 'grOdmoDacA8'
    },
    {
        name: 'US Presidential Election result',
        videoId: 'Ip8M_Pox1TM'
    },
    {
        name: 'Palladium and Platinum price movements',
        videoId: 'KjbPMf0r2uY'
    },
]

export default webinarsData;