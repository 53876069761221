import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LogoIcon, NavBarListItem } from '../styles/componentStyled';
import { AppBar, Box, Toolbar, TextField, IconButton, Drawer, List, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';






const Navbar = () => {
    // useLocation is hook  bevause of that It must be callet at top level function component
    const location = useLocation();

    // Page Routes data
    const pages = [{ name: 'Home', href: '/home' }, { name: 'AI Blog', href: '/ai-blog' }, { name: 'AI Tools', href: '/ai-tools' },{ name: 'AI Trader', href: '/ai-trader' }, { name: 'Webinars', href: '/webinar' }];

    // Toggle state for mobile menu (Drawer)
    const [toggleMenu, setToggleMenu] = useState(false);
    const toggleNavMenu = () => {
        setToggleMenu(!toggleMenu);
    };




    return (
        <AppBar color='primary' position='sticky' >
            <Toolbar>


                <IconButton href='http://localhost:3000/'  >
                    <LogoIcon
                        component="img"
                        src="/images/top500-logo.png"
                        alt="AI Trader Logo"
                    />
                </IconButton>

                {/* Menu button for mobile view */}
                <IconButton onClick={toggleNavMenu} sx={{ display: { sm: 'none' } }}>
                    <MenuIcon color='secondary' fontSize='medium' />
                </IconButton>

                {/* Navigation  for desktop  */}

                <List component="nav" sx={{ display: { xs: 'none', sm: 'flex', alignItems: 'center' } }}>
                    {pages.map(
                        (page, index) => {
                            const isActive = location.pathname === page.href
                            return (
                                <Box component={Link} to={page.href} key={index} sx={{ color: isActive ? '#00FBF4' : '#fff', textDecoration: 'none' }}>
                                    <NavBarListItem >
                                        {/* disableTypography- if I want to apply fontWeight here I must disable child element */}
                                        <ListItemText
                                            primary={page.name}
                                            disableTypography
                                            size='small'
                                            placeholder="Search"
                                            sx={{ fontSize: '13px', whiteSpace: 'nowrap', fontWeight: '500' }}
                                        />
                                    </NavBarListItem>
                                </Box>
                            )
                        }
                    )}
                    <TextField
                        variant="outlined"
                        color='secondary'
                        size='small'
                        placeholder="Search"
                        focused
                        hiddenLabel
                    />

                </List>

                {/* Drawer for mobile */}
                <Drawer anchor="top" open={toggleMenu} onClose={toggleNavMenu} >
                    <IconButton onClick={toggleNavMenu} sx={{ padding: 4 }}>
                        <CloseIcon color='secondary' fontSize='medium' />
                    </IconButton>
                    <List component="nav" sx={{ marginLeft: 'auto', paddingBottom: '16px' }}>
                        {pages.map((page, index) => {
                            const isActive = location.pathname === page.href
                            return (
                                <Box component={Link} to={page.href} key={index} sx={{ color: isActive ? '#00FBF4' : '#fff', textDecoration: 'none' }}>
                                    <NavBarListItem onClick={toggleNavMenu} >
                                        <ListItemText primary={page.name} disableTypography size='small' placeholder="Search" sx={{ fontSize: '13px' }} />
                                    </NavBarListItem>
                                </Box>
                            )
                        })}
                        <TextField
                            variant="outlined"
                            color='secondary'
                            size='small'
                            placeholder="Search"
                            focused
                            hiddenLabel
                        />


                    </List>
                </Drawer>
            </Toolbar>
        </AppBar>
    )
};

export default Navbar;